<template>
    <div class="update-task-form">
        <h1>{{isUpdateMode ? $t('btSave') : $t('btAdd')}}</h1>
        <ap-form :fields="fields" :values="task" :buttons="buttons" @change="save" @close="$emit('close')"></ap-form>
    </div>
</template>

<script>

    import ApForm from "../../components/ApForm"

    export default {

        // Components list
        components: {ApForm},

        // Properties
        props: ["id", "group", "member_id", "templateMode"],

        // Form dadta
        data: () => ({
            ages: {
                0: '4-6',
                1: '7-10',
                2: '11-15',
                3: '16+',
            }
        }),

        computed:
            {
                // Form mode
                isUpdateMode() {
                    // Return mode
                    return this.id && this.task.family_id != ""? 1 : 0;
                },

                // Get family
                family() {

                    // Get family
                    return this.$get("family/get");
                },

                // Load task
                task() {

                    // Init values
                    let vals = {};

                    // check for parameter
                    if (this.id) {
                        // Load task
                        vals = this.$unreactGet('task/get', this.id)
                    } else {
                        vals = {
                            family_id: this.family.id,
                            group: this.group,
                            owner_id: this.$get("family/getCurrentUser").id,
                            reward: 10,
                            measurable: 0,
                            ratio: 1,
                            description: '',
                            periodic: null,
                            gender: ['male', 'female'],
                            ages: [],
                            is_auto_addition: false
                        };
                    }

                    // Check for predefined member
                    if(this.member_id) {
                        // Check for assignees
                        if (!vals.assignees) vals.assignees = [];

                        // Add current member as assignee
                        if (!vals.assignees.includes(this.member_id)) vals.assignees.push(this.member_id);
                    }

                    // Return vals
                    return vals;
                },

                // fields list
                fields() {

                    // get assignees
                    let assignees = {};
                    Object.values(this.$get("member/list")).map((i) => {assignees[i.id] = i.name;} );

                    // Fields list
                    let flds = {
                        title: {
                            type: "string",
                            title: this.$t("ttlTaskTitle") + " (alias)",
                            validation: ["required"]
                        },
                        description: {
                            type: "textarea",
                            title: this.$t('ttlTaskDescr') + " (alias)"
                        },
                        periodic: {
                            type: 'schedule',
                            title: 'Schedule'
                        },
                        reward: {
                            type: 'range',
                            title: this.$t("ttlRewardAmount"),
                            validation: ["required"],
                            min: 1,
                            max: 100,
                            step: 1,
                            show: (form) => !parseInt(form['measurable'])
                        },
                        ratio: {
                            type: "numeric",
                            title: this.$t("ttlChangeRatio"),
                            unit: this.$t('ttlRepetitionsFull'),
                            min: 1,
                            max: 100,
                            step: 1,
                            show: (form) => parseInt(form['measurable'])
                        },
                        measurable: {
                            type: "switch",
                            title: this.$t("ttlMeasurable")
                        },
                        bundles: {
                            type: 'select',
                            title: 'Bundles',
                            multiple: true,
                            options: this.bundles
                        },
                        ages: {
                            title: 'Ages',
                            type: 'checkbox',
                            options: [
                                {
                                    title: '4-6',
                                    val: Object.keys(window.app.$yearsTable)[0]
                                },
                                {
                                    title: '7-10',
                                    val: Object.keys(window.app.$yearsTable)[1]
                                },
                                {
                                    title: '11-13',
                                    val: Object.keys(window.app.$yearsTable)[2]
                                },
                                {
                                    title: '14-17',
                                    val: Object.keys(window.app.$yearsTable)[3]
                                },
                                {
                                    title: '18+',
                                    val: Object.keys(window.app.$yearsTable)[4]
                                },
                            ],
                            validation: ["required"]
                        },
                        gender: {
                            title: 'Gender',
                            type: 'checkbox',
                            options: [
                                {
                                    title: 'Female',
                                    val: 'female'
                                },
                                {
                                    title: 'Male',
                                    val: 'male'
                                }
                            ],
                            validation: ["required"]
                        },
                        is_auto_addition: {
                            title: 'Automatic addition',
                            type: 'switch-bool',
                        }
                    };

                    // Set assignees for not template mode
                    if(!this.templateMode) flds['assignees'] = {type: "select", title: this.$t("ttlAssignees"), validation: ["required"], multiple:true,  options: assignees};

                    // Return fields list
                    return flds;
                },

                // Buttons list
                buttons() {

                    // Buttons list
                    let btns = {close: true};

                    // Save button
                    btns['save'] = {
                        title: this.isUpdateMode ? this.$t('btSave') : this.$t('btAdd'),
                        class: "md-primary md-raised",
                        type: "submit"
                    }

                    // Return list
                    return btns;
                },

                bundles() {
                    let bundles = Object.values(this.$unreactGet('bundles/list'))
                    let returnData = {};

                    bundles.forEach(item => {
                        returnData[item.id] = item.title + ' (' + item.gender.join(', ') + ', ' + this.ages[item.bundle_age] + 'y)'
                    })

                    return returnData
                }

            },

        // Methods list
        methods:
            {
                /**
                 * Add task
                 */
                save(values) {

                    // Set update flag
                    let isUpdate = this.isUpdateMode;

                    // Set owner and family
                    if (this.templateMode) {
                        // Reset vars
                        values.family_id = null;
                        values.owner_id = null;
                    } else {
                        // Vars
                        values.family_id = this.family.id;
                        values.member_id = this.member_id;
                        values.owner_id = this.$get("family/get").id;
                    }

                    if (values.order) {
                        values.order = parseInt(values.order);
                    } else {
                        values.order = null;
                    }

                    // Check for update mode
                    if (!isUpdate) {
                        // Add task to the family
                        this.$dispatch("task/create", values).then(() => {
                            this.$dispatch('translation/set', {
                                id: values.title,
                                language: this.$get('translation/currentLanguage'),
                                value: values.title,
                                group: 'common'
                            }).then(() => {
                                this.$emit("close");
                            });
                        });
                    } else {
                        // Add task to the family
                        this.$dispatch("task/update", this.$api.mergeObjects(values, {id: this.id})).then(() => {
                            this.$emit("close");
                        });
                    }
                },
            }
    }
</script>


<style lang="scss">
    .control-numeric .md-field{
        label {
            top: -5px;
        }
    }
    .bundles {
        &.md-select-menu {
            .md-list-item-content>.md-checkbox:first-child, .md-list-item-content>.md-radio:first-child {
                margin-right: 15px!important;
            }
        }
    }
    .update-task-form {
        padding: 16px;
        max-height: 600px;
        overflow: scroll;
        .ap-form {
            .checkboxes {
                display: flex;
                flex-direction: row;
            }
        }
    }

    .control-schedule {
        .md-field {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .picker-time-field {
                margin-left: 130px;
                cursor: pointer;
            }
        }
    }
</style>

<template>

    <div class="manage-tasks">

        <h1>Tasks templates management</h1>

        <md-dialog :md-active.sync="showDialog" :md-fullscreen="false" class="popup-dialog">
            <update-task :id="task_id" templateMode="true" :group="activeTab" @close="showDialog=false"/>
        </md-dialog>

        <md-tabs class="md-transparent admin-groups-tabs task-groups" md-alignment="left">
            <template v-for="(group, alias) in $store.getters['task/groups']">
                <md-tab
                    :key="alias"
                    :id="alias"
                    :md-icon="require(`@/assets/images/categories/${group.icon}.svg`)"
                    @click="activeTab = alias"
                ></md-tab>
            </template>
        </md-tabs>

        <md-table v-model="list">
            <md-table-toolbar>
                <h1 class="md-title">Tasks</h1>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Image" md-sort-by="id">
                    <img :src="require(`@/assets/images/categories/${item.group}.svg`)" alt="" width="50" height="50">
                </md-table-cell>
                <md-table-cell md-label="Title (Alias)" md-sort-by="id"><a href="#" @click.prevent="update(item)">{{$t(item.title)}}</a></md-table-cell>
                <md-table-cell md-label="Description (Alias)" md-sort-by="name">{{ $t(item.description) }}</md-table-cell>
                <md-table-cell md-label="Reward" md-sort-by="gender">{{ item.reward }}</md-table-cell>
                <md-table-cell md-label="Gender" md-sort-by="gender">{{ item.gender }}</md-table-cell>
                <md-table-cell md-label="Ages" md-sort-by="ages">{{ getAges(item.ages) }}</md-table-cell>
                <md-table-cell md-label="Automatic addition" md-sort-by="ages">{{ item.is_auto_addition ? 'Yes' : 'No' }}</md-table-cell>
                <md-table-cell md-label="Periodic">{{ item.periodic && item.periodic.repeat ? 'Periodic task' : 'Permanent' }}</md-table-cell>
                <md-table-cell md-label="Action">
                    <md-button @click="deleteTask(item)">
                        <md-icon>delete_outline</md-icon>
                    </md-button>
                </md-table-cell>
            </md-table-row>
        </md-table>

        <md-button class="md-fab md-primary md-fab-bottom-right" @click="add">
            <md-icon>add</md-icon>
        </md-button>
    </div>
</template>

<script>

    import UpdateTask from "../Task/UpdateTask";
    export default {

        // Components list
        components: {UpdateTask},

        // Data
        data()
        {
            // Current tab
            return {
                activeTab: 'learn',
                showDialog: false,
                task_id: 0
            };
        },


        // Computed
        computed:
            {
                // get tasks list
                list() {
                    // Get list
                    return this.$get("task/templateList", this.activeTab).sort((a, b) => {
                        if(a.order === null){
                            return 1;
                        }
                        else if(b.order === null){
                            return -1;
                        }
                        else if(a.order === b.order){
                            return 0;
                        }
                        else {
                            return a.order < b.order ? -1 : 1;
                        }
                    });
                }
            },

        // Mounted
        mounted()
        {
            // Loading tasks
            this.$dispatch("task/loadTemplates");
        },

        // Methods
        methods:
            {
                getAges(ages) {

                    let a = ''
                    ages.map(age => {
                        if (window.app.$yearsTable[age]) {
                            a += window.app.$yearsTable[age] + ', '
                        }
                    })
                    return a
                },
                /**
                 * Add new task
                 */
                add()
                {
                    // Add task
                    this.task_id = 0;
                    this.showDialog = true;
                },

                /**
                 * Update member
                 * @param task
                 */
                update(task)
                {
                    // Go to update page
                    this.task_id = task.id;
                    this.showDialog = true;
                },

                /**
                 * Delete task
                 */
                deleteTask(task) {
                    // Confirm deletion
                    if (!confirm(this.$t("qDelete") + " " + task.title)) return;

                    // Add task to the family
                    this.$dispatch("task/delete", {id: task.id}).then(() => {
                        this.$emit("close");
                    });
                }
            }
    }
</script>

<style lang="scss">

    @import './src/sass/groups.scss';

    .manage-tasks {
        padding-bottom: 80px;
    }

</style>

